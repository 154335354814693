export const columns = [

{
    title: '部门名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '是否启用',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]
